<template>
  <div>
    <div class="bg" v-loading="loading">
      <div class="form" v-if="!loading">
        <el-form :model="form" label-width="80px" :rules="rules" ref="ruleForm">
          <template v-if="!isSet">
            <el-form-item label="设置金额" required  prop="money">
              <el-input v-model="form.money" placeholder="请输入金额，单位万元"></el-input>
            </el-form-item>
            <el-form-item label="职位">
              <el-select v-model="form.role_id"   collapse-tags clearable filterable>
                <template v-for="item in roleList">
                  <el-option :key="item.id" :label="item.title" :value="item.id"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="设置密码" required  prop="password">
              <el-input v-model="form.password" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">启动初始化</el-button>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="密码" required  prop="password">
              <el-input v-model="form.password" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">解除</el-button>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBossKeyReq,
  setBossKeyReq,
  unsetBossKeyReq,
  rolelistReq
} from '@/api/system/init';
export default {
  name: 'Init',
  data() {
    return {
      loading: false,
      isSet: false,
      form: {},
      roleList: [],
      rules: {
        password: [
          { required: true, message: '请输入密码' },
        ],
        money:[
          { required: true, message: '请输入金额' },
        ]
      },
    };
  },
  created() {
    this.getBossKey();
    this.getRoleList();
  },
  methods: {
    getRoleList() {
      rolelistReq()
        .then((res) => {
          if (res.code === 1) {
            // const filterRolesArr = [];
            // res.data.forEach((item) => {
            //   const obj = {
            //     text: item.title,
            //     value: item.title,
            //   };
            //   filterRolesArr.push(obj);
            // });
            // this.filterRolesArr = filterRolesArr;
             this.roleList = res.data;
          }
        });
    },
    getBossKey() {
      this.loading = true;
      getBossKeyReq()
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.form = {};
            if (res.data.status) {
              this.isSet = true;
            } else {
              this.isSet = false;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功
          if (!this.isSet) {
            // 需要设置密码
            setBossKeyReq({
              password: this.form.password,
              money:this.form.money,
              roleId:this.form.role_id
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  });
                  this.getBossKey();
                }
              });
          } else {
            unsetBossKeyReq({
              password: this.form.password,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  });
                  this.getBossKey();
                }
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  height: calc(100vh - 85px);
  background: white;
  padding: 20px;
  box-sizing: border-box;
  .form {
    width: 50%;
  }
}
</style>
