import { post } from '@/utils/request';

const getBossKeyReq = () => post({
  url: '/store/system.BossKey/getBossKey',
});

const setBossKeyReq = (params) => post({
  url: '/store/system.BossKey/setBossKey',
  data: {
    ...params,
  },
});

const unsetBossKeyReq = (params) => post({
  url: '/store/system.BossKey/unsetBossKey',
  data: {
    ...params,
  },
});
const rolelistReq = () => post({
  url: '/store/authority.group/list',
});
export {
  getBossKeyReq,
  setBossKeyReq,
  unsetBossKeyReq,
  rolelistReq,
};
